require("typeface-barlow-semi-condensed")
require("typeface-questrial")

// import React from "react";
// import { ThemeProvider } from 'styled-components'
// import theme from './config/theme'

// export const wrapRootElement = ({ element }) => {
//   const ConnectedRootElement = (
//     <ThemeProvider theme={theme}>
//       {element}
//     </ThemeProvider>
//   );
//   return ConnectedRootElement;
// };
